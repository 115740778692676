import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import PageBanner from "@/components/page-banner";
import AboutOne from "@/components/about-one";
import CallToActionOne from "@/components/call-to-action-one";
import TeamCarousel from "@/components/team-carousel";
import VideoOne from "@/components/video-one";
import SubscribeForm from "@/components/subscribe-form";
import TestimonialsOneCarousel from "@/components/testimonials-carousel";
import FeatureTabOne from "@/components/feature-tab-1";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import ServiceDetails from "@/components/service-details";

const GiftCardsPage = () => {
  const isBrowser = typeof window !== "undefined";

  if (isBrowser) {
    (function (g, i, f, t, u, p, s) {
      g[u] =
        g[u] ||
        function () {
          (g[u].q = g[u].q || []).push(arguments);
        };
      p = i.createElement(f);
      p.async = 1;
      p.src = t;
      s = i.getElementsByTagName(f)[0];
      s.parentNode.insertBefore(p, s);
    })(
      window,
      document,
      "script",
      "https://cdn.giftup.app/dist/gift-up.js",
      "giftup"
    );
  }

  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Gift Cards">
          <HeaderOne />
          <PageBanner title="Gift Cards" name="Gift Cards" />
          {/* <ServiceDetails /> */}
          <br />
          <br />
          <div
            class="gift-up-target"
            data-site-id="03fdca80-23c3-4e9d-9c2a-60a489c229d8"
            data-platform="Other"
          ></div>

          {/* <AboutOne />
          <TestimonialsOneCarousel />
          <SubscribeForm />
          <TeamCarousel />
          <VideoOne />
          <FeatureTabOne />
          <CallToActionOne extraClassName="ready" /> */}
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default GiftCardsPage;
