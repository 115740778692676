import React from "react";
import serviceS1 from "@/images/s1.jpg";
import serviceS2 from "@/images/s2.jpg";
const ServiceDetailsContent = () => {
  return (
    <div className="serviceArea">
      {/* <img src={serviceS1} alt="" /> */}
      <h2>About Fertile</h2>
      <p style={{ fontSize: "140%" }}>
        At Fertile, we provide for all your gardening needs. We offer the very
        best selection of trees, shrubs, perennials, annuals, and garden
        elements in Chicago. Our staff at Fertile is happy to help you choose
        the best flora for your space. One of Fertile’s many specialties is
        individualized garden design and installation. No matter how large or
        small the project, we can help you plan and execute a rooftop garden, a
        backyard oasis, or a welcoming shop front. Fertile’s seasonal container
        design offers a colorful way to update your decor, and can fit any
        budget. <br />
        <br />
        Do you need help cleaning up your lawn after a long winter, or solving a
        troublesome part of your garden? Consult Fertile for getting your
        greenery in the best shape possible. Our staff is comprised of
        horticulturists, master gardeners, growers, and designers who work in
        tandem to solve your landscaping problems and garden layouts. Whether
        you need to cut down and remove a massive tree, or arrange a tiny
        terrarium of succulents, Fertile can answer your questions and help
        finish your projects!
        <br />
        <br />
        We can help you stock up your tool shed for the season, as we sell
        fertilizer, potting material, and insecticides. We have tools and
        watering equipment for maintaining your plants, and nutrient mixes for
        potted specimens. Our containers and pots come in a variety of colors
        and shapes to fit your aesthetic for patio or rooftop plantings, and our
        beautiful garden elements add the finishing touch for your perfect
        design.
      </p>
      {/* <div className="row gaping">
        <div className="col-lg-6 col-sm-12 col-md-6">
          <img src={serviceS2} alt="" />
        </div>
        <div className="col-lg-6 col-sm-12 col-md-6">
          <h3>planning & strategy</h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
          <ul>
            <li>
              <i className="fa fa-check-square"></i>Research beyond the business
              plan
            </li>
            <li>
              <i className="fa fa-check-square"></i>Marketing options and rates
            </li>
            <li>
              <i className="fa fa-check-square"></i>The ability to turnaround
              consulting
            </li>
            <li>
              <i className="fa fa-check-square"></i>Help companies into more
              profitable
            </li>
            <li>
              <i className="fa fa-check-square"></i>Customer engagement matters
            </li>
          </ul>
        </div>
      </div>
      <p>
        Need something changed or is there something not quite working the way
        you envisaged? Is your van a little old and tired and need refreshing?
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged.
      </p>
      <p>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged.
      </p> */}
    </div>
  );
};

export default ServiceDetailsContent;
